.closeButton {
  position: fixed;
  top: 10px;
  right: 20px;
  font-size: 20px;
}

.iconCloseButton {
  vertical-align: middle;
  color: red;
  margin-right: 10px;
}

.fullscreenPanel {
  height: 100%;
  width: 100%;
}

.activeFullscreenPanel {
  padding-top: 50px !important;
  padding-left: 10px;
  padding-right: 10px;
}
