.title {
  position: fixed;
  top: 15px;
  left: 30px;
  display: flex;
  align-items: center;
  font-size: x-large;
  div {
    padding-top: 10px;
  }
}

.login_button {
  width: 300px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.inputForm {
  width: 300px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.language {
  position: fixed;
  top: 30px;
  right: 60px;
  p,
  div {
    font-size: 12px;
  }
}
