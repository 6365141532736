.activeInfoTable {
  th {
    text-align: center;
    height: 6vh;
    font-size: 2vh;
    line-height: 1.5vh;
    padding-top: 0vw;
    padding-bottom: 0vw;
    padding-right: 1vw;
    padding-left: 1vw;
    // border-top: 1px solid rgba(224, 224, 224, 1);
  }
  td {
    text-align: center;
    height: 8vh;
    font-size: 2vh;
    line-height: 1.5vh;
    padding-top: 0vw;
    padding-bottom: 0vw;
    padding-right: 1vw;
    padding-left: 1vw;
  }
  svg {
    height: 5vh !important;
    width: 5vh !important;
    top: calc(50% - 2.5vh) !important;
  }
}

.chipCell {
  font-size: 2vh !important;
  height: 5vh !important;
}

.tablePagination {
  min-height: 2vh;
  p,
  div {
    font-size: 2.5vh;
    padding: 0 0.5vw;
    margin: 0 0.5vw;
  }
}
