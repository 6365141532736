.optManage {
  height: 100%;
  text-align: start;
}

.title {
  font-size: 4vh !important;
  height: 15%;
}

.corporatFilterForm {
  height: 10%;
}

.corporatListForm {
  height: 75%;
}
