.container {
  align-items: start;
  gap: 40px;
  display: flex;
  align-items: center;
}

.table {
  border: 1px solid lightgray;
}

.table_header {
  background-color: rgb(235, 235, 235);
}

.table_row {
  cursor: pointer;
  &:hover {
    background-color: rgb(245, 245, 245);
  }
}

.checkbox_column {
  width: 50px;
}

.small_column {
  width: 10%;
  min-width: 100px;
}

.button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}