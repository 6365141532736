.iconField {
  display: flex;
  height: 30%;
  align-items: center;
  justify-content: start;
  font-size: 2vh;
  svg {
    height: 5vh;
    width: 5vh;
    padding-right: 1vw;
    g {
      fill: #1ea793;
    }
  }
}
.checkBoxField {
  height: 70%;
  overflow-y: auto;
  padding-left: 1vw;
  svg {
    color: white;
    height: 2vh;
    width: 2vh;
  }
  span {
    font-size: 2vh;
    z-index: 0 !important;
  }
}
