.paperBox {
  width: 100%;
  height: 27vh;
}

.title {
  font-size: 3vh !important;
  height: 10%;
  font-weight: bold;
  text-align: left;
  padding: 1vh;
}

.cardBox {
  display: flex;
  overflow-y: hidden;
  gap: 1vw;
  padding: 1vw;
  white-space: nowrap;
  height: 88%;
}
