.container {
  display: flex;
  width: fit-content;
  flex-direction: row;
  gap: 3px;
}

.file_name {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  width: 20rem;
  background-color: rgb(234, 234, 234);
  border: 1px solid lightgray;
}