.singleDisp {
  height: 15%;
  display: flex;
}
.pieChart {
  height: 35%;
  display: flex;
}
.trendChart {
  height: 50%;
  display: flex;
}
