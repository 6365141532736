.heatMap {
  height: 100%;
  position: relative;
  div:first-child(1) {
    height: 100%;
  }
}

.heatMapItem {
  height: 100%;
  overflow-x: auto;
  overflow-y: none;
}

.heatMapItemActive {
  height: 100%;
  padding-bottom: 1vh;
  overflow-x: auto;
  overflow-y: auto;
}

.screenHeatMap {
  height: 80%;
}

.buttonDiv {
  position: absolute;
  display: flex;
  right: 0%;
  bottom: -5%;
}

.fullscreen {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.fullscreenExit {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.screenButton {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.title {
  font-size: 2vw !important;
  padding-top: 1vh !important;
  padding-left: 1vw !important;
}

.tooltip {
  background-color: #fff !important;
  border: 0.1px solid;
  padding: 0px 1vh !important;
  position: relative;
  color: #000;
  font-size: 2vh !important;
}

.heatMapCell {
  height: 100%;
  width: 100%;
  align-content: center;
  overflow: "hidden";
}
