.title {
  font-weight: bold;
  color: #fff;
}
.circular {
  svg {
    color: #fff;
  }
  border: none;
}
