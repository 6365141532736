.dialog {
  overflow-x: hidden;
  :global(.MuiDialog-paper) {
    max-width: 65vw;
    max-height: 90vh;
  }
}

.dialogContent {
  width: 57vw;
  height: 85vh;
  padding: 0.5vh 1vw !important;
}

.title {
  font-size: 3vh !important;
  padding: 1vh 1vw !important;
}

.button {
  font-size: 3vh !important;
}

.list {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 2.5vh !important;
  span {
    font-size: 2.5vh !important;
  }
  ul {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  li {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.listTitle {
  font-weight: bold;
  padding-top: 0.2vh !important;
}

.listItem {
  padding-top: 1.5vh !important;
}

.explain {
  font-size: 2vh !important;
}

.inputText {
  font-size: 2.5vh !important;
  input,
  label {
    font-size: 2.5vh !important;
  }
}

.normalText {
  padding-right: 0.5vw !important;
}

.emailText {
  width: 30vw;
}

.passwordText {
  padding-top: 1vh !important;
  label {
    margin-top: 0vh !important;
  }
  :global(.MuiInputLabel-shrink) {
    top: -1vh;
  }
}

.createPassword {
  font-size: 2vh !important;
}

.toggleSwitch {
  padding-left: 1.5vw !important;
}
