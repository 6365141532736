.spreadSheet {
  height: 100%;
}

.title {
  float: left;
}

.tableCell {
  font-size: 1.5vh !important;
  padding: 0 0.5vw !important;
  line-height: 2.5 !important;
  text-align: center !important;
}
