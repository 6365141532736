.subCategory {
  height: 5.5vh;
}

.singleDisplayContent {
  height: 61vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.singleDisplay {
  height: 9.5vh;
  margin-bottom: 0.5vh;
  :global(.MuiGrid-container) {
    margin-top: 0 !important;
  }
  :global(.MuiGrid-item) {
    padding-top: 0 !important;
  }
}

.harfSingleBox {
  height: 9.5vh;
  margin-bottom: 0.2vh;
}

.harfSingleDisplay {
  height: 10.5vh;
  margin-bottom: 0.5vh;
}

.pdsButton {
  margin-bottom: 0.5vh;
  height: 5.5vh !important;
  font-size: 3.5vh !important;
  :global(.MuiButtonBase-root) {
    padding: 0.7vh !important;
  }
}

.selectItem {
  padding-bottom: 0.5vh;
}

.selectBox {
  :global(.MuiSelect-select) {
    padding: 1vh 1.5vw !important;
  }
}
