.dialog {
  overflow-x: hidden;
  :global(.MuiDialog-paper) {
    max-width: 60vw;
    max-height: 90vh;
  }
}

.dialogContent {
  width: 50vw;
  height: 85vh;
  padding: 1vh 1vw !important;
}

.title {
  font-size: 3vh !important;
  padding: 1vh 1vw !important;
}

.button {
  font-size: 3vh !important;
}

.list {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 2.5vh !important;
  span {
    font-size: 2.5vh !important;
  }
  ul {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  li {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.listTitle {
  font-weight: bold;
}

.listItem {
  padding-top: 2vh !important;
}

.explain {
  font-size: 2.5vh !important;
}

.inputText {
  font-size: 2.5vh !important;
  height: 5vh !important;
  :global(.MuiInputBase-root) {
    height: 5vh !important;
  }
  input,
  label {
    font-size: 2.5vh !important;
    line-height: 0;
    overflow: visible;
  }
}

.normalText {
  :global(.MuiInputLabel-shrink) {
    margin-top: 1vh !important;
  }
}

.passwordText {
  padding-bottom: 5vh !important;

  :global(.MuiInputLabel-shrink) {
    margin-top: 0.5vh !important;
  }
}

.toggleSwitch {
  padding-left: 1.5vw !important;
}
