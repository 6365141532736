.dialog {
  overflow-x: hidden;
  :global(.MuiDialog-paper) {
    max-width: 60vw;
  }
}

.activeDrag {
  background-color: #e0f7fa;
}

.dialogContent {
  width: 50vw;
  height: 50vh;
}

.selectFile {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.dateDiv {
  display: flex;
  align-items: center;
}

.calender {
  font-size: 2vh !important;
}

.title {
  font-size: 4vh !important;
}

.titleName {
  font-size: 2.5vh !important;
}

.dateTitle {
  font-size: 2vh !important;
}

.button {
  font-size: 3vh !important;
}
