.lineGraph {
  height: 100%;
}

.legend {
  // font-size: 5px;
}

.graphBox {
  position: relative;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart {
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  top: 0%;
  left: 4%;
  :global(.recharts-surface) {
    width: 101% !important;
  }
}

.chartFull {
  position: absolute;
  height: 89% !important;
  width: 85% !important;
  top: 7%;
  left: 8%;
  :global(.recharts-surface) {
    width: 101% !important;
  }
}

.yAxisLabelFull {
  padding-top: 1.5vh;
  font-size: 2vh;
  :global .recharts-label {
    text-anchor: middle;
  }
}

.yAxisLabel {
  font-size: 1.5vh;
  :global .recharts-label {
    text-anchor: middle;
  }
}

.buttonDiv {
  position: absolute;
  display: flex;
}

.fullscreen {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.fullscreenExit {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.screenButton {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.title {
  font-size: 2vw !important;
  padding-top: 1vh !important;
  padding-left: 1vw !important;
}
