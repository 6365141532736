.cardClass {
  width: 18vh;
  height: 18vh;
  min-width: 18vh;
  max-width: 18vh;
}

.cardContentClass {
  padding-top: 1vh !important;
  padding-bottom: 1vh !important;
  padding-left: 0.5vh !important;
  padding-right: 0.5vh !important;
}

.titleClass {
  font-size: 1.27vh !important;
  font-weight: bold !important;
  line-height: 1 !important;
  margin-bottom: 0 !important;
  word-break: break-all;
  white-space: normal;
}

.subTitleClass {
  font-size: 1.1vh !important;
  line-height: 1 !important;
  margin-bottom: 0 !important;
}

.backButton {
  width: 2vw;
  height: 15vh;
  min-width: 2vw;
  max-width: 2vw;
  top: 8vh;
}
.backButtonContent {
  height: 15vh;
  width: 2vw;
  min-width: 2vw;
  max-width: 2vw;
  svg {
    height: 3vh;
  }
}

.forwardButton {
  width: 2vw;
  height: 15vh;
  min-width: 2vw;
  max-width: 2vw;
  right: 1.5vw;
  top: 8vh;
}
.forwardButtonContent {
  height: 15vh;
  width: 2vw;
  svg {
    height: 3vh;
  }
}
