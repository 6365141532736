.iconField {
  display: flex;
  height: 30%;
  align-items: center;
  justify-content: start;
  font-size: 2vh;
  svg {
    height: 5vh;
    width: 5vh;
    padding-right: 1vw;
    g {
      fill: #1ea793;
    }
  }
}

.dateField {
  width: 8vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  font-size: 2vh;
}
