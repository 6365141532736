.title {
  padding-top: 2vh;
  padding-bottom: 1vh;
  font-size: 1.5vw !important;
}

.tableContainer {
  min-width: 27vw;
  width: 27vw;
  th,
  td {
    height: 8vh;
    font-size: 0.8vw;
    line-height: 1.5vh;
    padding-top: 0vw;
    padding-bottom: 0vw;
    padding-right: 1vw;
    padding-left: 1vw;
  }
}
