.icon {
  padding: 1vh !important;
}

.iconButton {
  font-size: 6vh !important;
  vertical-align: middle;
}

.container {
  padding-top: 1vh !important;
  padding-left: 3vw !important;
  padding-right: 0 !important;
  max-width: none !important;
}

.gridContainer {
  display: flex;
  align-items: center;
  margin-top: -2vh !important;
  width: calc(100% + 1vw) !important;
  margin-left: -1vw !important;
}

.dataInputList {
  height: 100%;
}

.gridItem {
  padding-top: 2vh !important;
  padding-left: 1vh !important;
  line-height: 0.1vh;
}

.title {
  font-size: 2.8vh !important;
}

.dataList {
  padding: 0px !important;
  min-width: 60vh !important;
}
