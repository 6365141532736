.besideBarGraph {
  height: 100%;
}

.label {
  height: 10%;
  font-size: 1.5vh !important;
  font-weight: bold !important;
  text-align: left;
  padding-top: 1vh;
  margin-left: 1vw !important;
}

.besideBarBox {
  height: 85%;
  padding-top: 1.5vh;
}
