@import "../../../common/styles/common_styles.scss";
.icons {
  text-align: left;
  align-items: center;
  height: $header-height;
  display: flex;
  svg {
    width: 28px;
    height: 28px;
  }
}
.econipass {
  width: 50px;
  margin: 10px;
}

.open_button {
  position: fixed;
  bottom: 0;
  left: calc($side-menu-open-width - 40px);
}

.close_button {
  position: fixed;
  bottom: 0;
  left: calc($side-menu-not-open-width - 40px);
}

.logout_button {
  position: fixed;
  bottom: 30px;
  left: 0;
}