.pcfHeatMap {
  height: 100%;
}

.tabPanel {
  height: 100%;
  width: 75vw;
}

.tabPanelContent {
  height: 100%;
  padding: 0 !important;
}

.tabs {
  width: 10vw !important;
  :global(.MuiTabs-indicator) {
    height: 5.3vh !important;
    width: 0.5vw !important;
  }
}

.tabButton {
  font-size: 2vh !important;
  padding: 1vh !important;
  min-height: 1vh !important;
  height: 5.3vh !important;
  min-width: 0 !important;
}
