.dialog {
  overflow-x: hidden;
  :global(.MuiDialog-paper) {
    max-width: 80vw;
    max-height: 90vh;
  }
}

.dialogContent {
  width: 75vw;
  height: 85vh;
  padding: 1vh 1vw !important;
}

.title {
  font-size: 3vh !important;
  padding: 1vh 1vw !important;
}

.inputSearchText {
  height: 8%;
  input {
    font-size: 2vh !important;
  }
}

.list {
  height: 84%;
  padding: 0px;
  span {
    font-size: 2.5vh;
  }
  ul {
    padding: 0px;
  }
  li {
    padding: 0px;
  }
}

.listTitle {
  font-weight: bold;
}

.listItem {
  padding-top: 2vh !important;
}

.customerName {
  span {
    font-size: 3vh !important;
  }
}

.checkedList {
  background-color: #bdbdbd !important;
}

.addButton {
  font-size: 2vh !important;
  justify-self: center;
  height: 8%;
  width: 80%;
  display: flex !important;
}

.assignCustomer {
  padding: 1vh !important;
}

.assignItemTitle {
  font-size: 4vh !important;
  padding-left: 0.5vw;
}

.removeButton {
  height: 5vh;
  width: 5vh;
  float: right;
}

.select {
  width: 15vw;
}

.selectTitle {
  padding-left: 0.5vw;
  width: 45%;
}

.selectItem {
  width: 55%;
}

.button {
  font-size: 3vh !important;
}
