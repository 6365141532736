.language {
  padding-left: 1vw;
  p {
    font-size: 3vh;
    padding-top: 0px;
  }
  div {
    font-size: 3vh;
    height: 7vh !important;
    align-content: center;
  }
}

.title {
  font-size: 4vh !important;
}

:global(.MuiMenuItem-root) {
  font-size: 3vh !important;
}
