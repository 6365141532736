.container {
  gap: 50px;
  align-items: center;
}

.create_segment{
  gap: 20px;
}

.create_title {
  text-align: left;
}

.format_segment {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.format_title {
  width: 100%;
  text-align: left;
}