.segment {
  padding: 10px;
}

.segment_content {
  width: 90%;
  background-color: rgb(245, 244, 244);
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 10px;
  white-space: pre-line;
}

.button_container {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 30px;
}
