@import "../../../common/styles/common_styles.scss";
.accordion {
  width: 100%;
  background-color: $base-sub-color !important;
  color: #ffffff !important;
  text-align: start;
  margin-left: 0.2vw !important;
}

.searchField {
  height: 100%;
  padding: 1vh 1vw 1vh !important;
  :global(.MuiGrid-container) {
    margin-top: -2vh;
  }
}
