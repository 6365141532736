@import "../../../common/styles/common_styles.scss";
.listItem {
  height: 7vh;
  padding-top: 0.8vh !important;
  padding-bottom: 0.8vh !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.selectedItem {
  background-color: $side-menu-select-color;
  div {
    background-color: $side-menu-select-color;
  }
}

.selectedSettingItem {
  background-color: $setting-page-select-color;
  div {
    background-color: $setting-page-select-color;
  }
}

.childItem {
  padding-left: 0.5vw !important;
}

.sideButton {
  padding: 0px 0px 0px 1vw !important;
}

.title {
  span {
    font-size: $side-menu-fontsize;
    font-weight: bold;
  }
}

.itemIcon {
  svg {
    height: 1.8vw;
    width: 1.8vw;
  }
}
