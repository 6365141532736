.title {
  text-align: center;
  padding-bottom: 1vh;
  height: 10%;
  font-size: 4vh !important;
}

.explanation {
  font-size: 3vh !important;
}

.inputForm {
  height: 90%;
}

.input {
  width: 100%;
  float: left;
}
.inputLabel {
  float: left;
  font-size: 3vh !important;
}

.multiline {
  width: 100%;
  float: left;
}
.multilineLabel {
  float: left;
  font-size: 3vh !important;
}
.submitButton {
  margin-top: 2vh !important;
  width: 50%;
}
