.imageDiv {
  height: 50%;
  align-items: center;
  justify-items: center;
  display: flex;
  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
}

.imageButton {
  padding: 0 !important;
}

.subTitle {
  word-break: break-all;
  white-space: normal;
}
