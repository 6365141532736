.usersManage {
  height: 100%;
  text-align: start;
}

.title {
  font-size: 4vh !important;
}

.usersButtonForm {
  height: 15%;
}

.usersFilterForm {
  height: 10%;
}

.usersListForm {
  height: 70%;
}
