@import "./econipass/common/styles/common_styles.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// タブ共通スタイル
.MuiTabs-root {
  background-color: #959595 !important;
  .MuiTab-root {
    color: white !important;
    padding: 0;
  }
  .MuiTabs-flexContainer {
    height: 100%;
    button {
      height: 100%;
      min-height: 0;
    }
  }
  .Mui-selected {
    background-color: $base-color;
  }
  .MuiTabs-indicator {
    height: 0.8vh !important;
    width: 50% !important;
  }
}

.MuiGrid-root {
  height: 100%;
  width: 100%;
}

// フルスクリーンCSS設定
.fullscreen {
  background-color: white;
}

// カレンダーCSS設定
.react-datepicker__tab-loop {
  position: absolute;
  width: 40vw;
}

.react-datepicker__header {
  font-size: 2vh !important;
}
.react-datepicker {
  font-size: 2vh !important;
}

.react-datepicker__navigation {
  height: 5vh !important;
  width: 5vh !important;
}

// Paperコンポーネント枠線設定
.MuiPaper-root {
  border-width: 0.11vh;
  border-color: #b3b3b3;
  border-style: solid;
}

.recharts-tooltip-wrapper {
  z-index: 1000;
}

.recharts-legend-item {
  margin-right: 0.5vw !important;
  svg {
    height: 2vh;
    width: 2vh;
    margin-right: 0.5vw !important;
  }
}

.recharts-brush {
  rect {
    height: 1vh !important;
  }
}

// 凡例文字色設定
.recharts-legend-item-text {
  color: #000 !important;
}

.MuiTooltip-tooltip {
  padding: 0 !important;
}

.MuiDialogTitle-root,
.MuiDialogContent-root {
  padding: 3vh 2vw !important;
}

.MuiButtonBase-root,
.MuiChip-label {
  padding: 1vh 1vw !important;
}
