@import "../../../common/styles/common_styles.scss";
.spaceItem {
  padding-left: 1vw;
}

.iconImage {
  vertical-align: middle;
  color: $base-color;
  background-color: white;
  border-radius: 10%;
  height: 1.6vw !important;
  width: 1.6vw !important;
}

.iconTitle {
  color: white;
  padding-left: 0.5vw;
  font-size: $header-menu-fontsize !important;
  font-weight: bold !important;
}

.iconButton {
  font-size: 7vh !important;
  vertical-align: middle;
}

.title {
  display: flex;
  align-items: center;
}

.dataList {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.dialog {
  :global(.MuiPaper-root) {
    width: 40vw !important;
    max-width: 40vw !important;
  }
}

.dialogContent {
  padding: 2vw !important;
  font-size: 3vh !important;
}

.dialogActions {
  padding: 2vh !important;
}

.dialogTitle {
  font-size: 3vh !important;
  padding: 2vh 2vw !important;
}

.dialogListItem {
  font-size: 3vh !important;
}

.dialogCloseButton {
  font-size: 2vh !important;
  padding: 1vh !important;
  min-width: 4vw !important;
}
