.displaySwitching {
  height: 20%;
  margin-bottom: 1px;
  padding-left: 10px;
  float: right;
}

.topContent {
  display: flex;
  align-items: center;
  justify-content: end;
}

.pcfHeatMap {
  height: 80%;
}

.selectItem {
  padding-bottom: 1vh;
}

.selectBox {
  :global(.MuiSelect-select) {
    padding: 1vh 1.5vw !important;
  }
}
