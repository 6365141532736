@import "../../../common/styles/common_styles.scss";

.appBar {
  display: flex;
  position: fixed;
}

.toolBar {
  color: #ffffff;
  min-height: $header-height !important;
  height: $header-height !important;
  padding: 0 !important;
}
.normalHeader {
  background-color: $base-color;
}
.settingHeader {
  background-color: $setting-page-color;
}
.openSide {
  width: calc(100% - $side-menu-open-width) !important;
  margin-left: $side-menu-open-width;
}

.notOpenSide {
  width: calc(100% - $side-menu-not-open-width) !important;
  margin-left: $side-menu-not-open-width;
}

.openSideAccordion {
  width: calc(100% - $side-menu-open-width) !important;
  max-width: 5000px !important;
  margin-left: $side-menu-open-width !important;
  margin-top: $header-height;
  padding-left: 0px !important;
  padding-right: 0px !important;
  z-index: 1100;
}

.notOpenSideAccordion {
  width: calc(100% - $side-menu-not-open-width) !important;
  max-width: 5000px !important;
  margin-left: $side-menu-not-open-width !important;
  margin-top: $header-height;
  padding-left: 0px !important;
  padding-right: 0px !important;
  z-index: 1100;
}

.headerLeft {
  margin: 0 0 0 0;
}

.headerRight {
  margin: 0 0 0 auto;
}
