.trendGraph {
  height: 100%;
}

.tabPanel {
  height: 100%;
  padding: 0 1vw 2vw 1vw !important;
}

.tabList {
  float: right;
  height: 4vh !important;
  width: 14vw;
  min-width: 14vw !important;
  min-height: 4vh !important;
}

.title {
  float: left;
  font-size: 1.5vh !important;
  padding-top: 1vh !important;
  padding-left: 1vw !important;
}

.toolTipIcon {
  float: left;
  margin-top: 1vh !important;
  height: 2vh !important;
  width: 2vh !important;
}

.tab {
  font-size: 1.5vh !important;
  width: 7vw;
  min-width: 7vw !important;
}
