.card {
  position: absolute;
  opacity: 90%;
  z-index: 1;
}
.cardContent {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
