.dialog {
  overflow-x: hidden;
  :global(.MuiDialog-paper) {
    max-width: 60vw;
  }
}

.activeDrag {
  :global(.MuiDialog-paper) {
    max-width: 60vw;
    background-color: #e0f7fa;
  }
}

.dialogContent {
  width: 50vw;
  height: 50vh;
}

.dragAndDropArea {
  text-align: center;
  font-weight: bold;
  font-size: 4vh;
}

.fileList {
  height: 30vh;
}

.fileUpload {
  font-size: 15vh !important;
}

.selectFile {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 3vh !important;
}

.fileName {
  span {
    font-size: 3vh !important;
  }
}

.title {
  font-size: 4vh !important;
}

.titleName {
  font-size: 2.5vh !important;
}

.button {
  font-size: 3vh !important;
}
