.selectBox {
  align-items: center;
  display: flex;
}

.selectCategory {
  float: left;
  padding-right: 1vw;
  font-size: 2vh !important;
  background-color: white;
  :global(.MuiSelect-select) {
    padding: 1.5vh 1.5vw;
  }
}

.title {
  padding-right: 1vw;
  font-size: 2vh !important;
  float: left;
}
