.leftField {
  display: flex;
  width: 100%;
}

.searchButton {
  width: 10vw;
  height: 5vh;
  font-size: 2.5vh !important;
  margin-right: 0.5vw !important;
  margin-bottom: 1vh !important;
}

.resetButton {
  width: 10vw;
  height: 5vh;
  font-size: 2.5vh !important;
  margin-bottom: 1h !important;
}

.dateField {
  font-size: 2.5vh !important;
  align-content: center;
  margin-bottom: 1vh !important;
  :global(.react-datepicker-wrapper) {
    padding-right: 0.5vw;
  }
}

.datePicker {
  width: 10vw;
  font-size: 2.5vh !important;
}
