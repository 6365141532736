.passwordForm {
  button {
    padding: 1vh !important;
    svg {
      width: 3vh;
      height: 3vh;
    }
  }
}

.password {
  margin-top: 1.5vh !important;
  padding-bottom: 0 !important;

  &:global(.MuiInputLabel-shrink) {
    margin-top: 0vh !important;
    padding-bottom: 0 !important;
  }
}
