@import "../../../common/styles/common_styles.scss";

.drawer {
  flex-shrink: 0;
  position: fixed;
  div {
    background-color: $base-sub-color;
    color: white;
    min-width: 40px;
  }
}

.openSide > *:first-child {
  width: $side-menu-open-width !important;
  overflow-x: hidden;
}

.notOpenSide > *:first-child {
  width: $side-menu-not-open-width !important;
  overflow-x: hidden;
}
