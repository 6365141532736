.singleDisplay {
  height: 11vh;
  margin-bottom: 0.5vh;
  :global(.MuiGrid-container) {
    margin-top: 0 !important;
  }
  :global(.MuiGrid-item) {
    padding-top: 0 !important;
  }
}

.harfSingleDisplay {
  height: 11vh;
}

.singleDisplayContent {
  height: 70vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.pdsButtonItem {
  margin-bottom: 0.2vh;
}

.pdsButton {
  margin-bottom: 0.5vh;
  height: 8vh !important;
  font-size: 3vh !important;
}
