.leftContent {
  height: 85vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}
.singleDisplay {
  height: 10vh;
  margin-bottom: 0.5vh;
  :global(.MuiGrid-container) {
    margin-top: 0 !important;
  }
  :global(.MuiGrid-item) {
    padding-top: 0 !important;
  }
}
.barGraph {
  height: 13vh;
  margin-bottom: 0.5vh;
}
.harfSingleBox {
  height: 14vh;
  margin-bottom: 0.5vh;
}
.harfSingleDisplay {
  height: 10vh;
}

.pdsButtonItem {
  margin-bottom: 0.2vh;
}

.pdsButton {
  margin-bottom: 0.5vh;
  height: 7vh !important;
  font-size: 3vh !important;
}
