.goalValue {
  display: flex;
  float: left;
  font-size: 2vh !important;
  height: 5.5vh;
}

.numberGoalLabel {
  font-size: 2vh !important;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1vw;
  margin-bottom: 1vh;
}

.numberGoal {
  label {
    font-size: 2vh !important;
  }
  input {
    font-size: 2vh !important;
  }
  :global(.MuiInputBase-root) {
    height: 4.5vh;
  }
}

.button {
  width: 4vw;
  height: 4.5vh;
  padding: 0.5vh !important;
  font-size: 2vh !important;
  margin-left: 0.5vh !important;
}
