@import "../../../common/styles/common_styles.scss";
.buttonField {
  display: flex;
  align-items: flex-end;
}
.resetButton {
  background-image: none !important;
  background-color: white !important;
  color: $base-color !important;
  margin-bottom: 1vh !important;
  font-size: 2vh !important;
  height: 6vh !important;
  min-width: 7vw !important;
  width: 7vw !important;
  padding: 0 !important;
}

.searchButton {
  background-image: none !important;
  background-color: white !important;
  color: $base-color !important;
  margin-bottom: 1vh !important;
  font-size: 2vh !important;
  height: 6vh !important;
  min-width: 7vw !important;
  width: 7vw !important;
  padding: 0 !important;
}
