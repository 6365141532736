.selectItemBox {
  justify-items: center;
}
.selectItem {
  width: 30vw;
}
.selectItemTitle {
  font-size: 2.5vh !important;
}
.leftGrid {
}
.rightGrid {
  text-align: end;
}
