.container {
  align-items: center;
  gap: 40px;
  display: flex;
}

.create_segment {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.element_title {
  width: 130px;
  text-align: start;
}

.element_content {
  width: 300px;
}

.textfield {
  width: 100%;
}

.selectbox {
  width: 150px;
}