.displaySwitching {
  display: flex;
  font-size: 2vh;
  float: right;
}

.label {
  align-items: center;
  justify-content: center;
  margin-right: 0.5vw;
  line-height: 3.5vh;
}

.toggleButtonGroup {
  font-size: 2vh !important;
  height: 2vh;
  align-items: center;
  justify-content: center;
  padding: 2vh !important;
  margin-bottom: 0.5vh !important;
}
