.login_button {
  width: 24vw;
  margin-left: auto !important;
  margin-right: auto !important;
}

.inputForm {
  width: 24vw;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 1vh !important;
}

.language {
  position: fixed;
  top: 3vh;
  right: 3vw;
  p,
  div {
    font-size: 1vw;
  }
}
