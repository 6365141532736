.graphBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.chart {
  position: absolute;
  top: 0%;
  left: 0%;
  :global(.recharts-legend-wrapper) {
    height: 4vh !important;
    overflow-y: auto;
  }
}

.chartFull {
  position: absolute;
  height: 90% !important;
  top: 7%;
  left: 5%;
  :global(.recharts-legend-wrapper) {
    height: 4vh !important;
    overflow-y: auto;
  }
}

.trendGraph {
  height: 100%;
}

.yAxisLabel {
  font-size: 1.5vh;
  transform-origin: 8% 12%;
}

.yAxisLabelFull {
  font-size: 1.5vh;
  transform-origin: 12% 19%;
}

.yAxisFull {
  padding-top: 1vh;
}

.brushFull {
  padding-top: 1vh;
}

.buttonDiv {
  position: absolute;
  display: flex;
}

.fullscreen {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.fullscreenExit {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.screenButton {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.title {
  font-size: 2vw !important;
  padding-top: 1vh !important;
  padding-left: 1vw !important;
}

.legend {
  svg {
    height: 1.5vh !important;
    width: 1.5vh !important;
  }
}
