.searchItem {
  height: 50%;
  padding-bottom: 5px;
}

.searchButtonGrid {
  display: grid;
  padding-left: 0px !important;
}

.unitSelectItems {
  padding-left: 0.5vw !important;
}

.selectItem {
  padding-bottom: 1vh;
}

.searchButtonItem {
  display: grid;
  align-items: flex-end;
  padding-left: 0.5vw !important;
}
