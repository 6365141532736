.pcfLineGraph {
  height: 100%;
  position: relative;
}

.title {
  float: left;
  font-size: 1.5vh !important;
  padding-top: 1vh !important;
  padding-left: 1vw !important;
}

.tabList {
  float: right;
  height: 4vh !important;
  width: 28vw;
  min-width: 28vw !important;
  min-height: 4vh !important;
  :global(.MuiTabs-indicator) {
    width: 25% !important;
  }
}

.tab {
  font-size: 1.5vh !important;
  width: 7vw;
  min-width: 7vw !important;
}

.tabPanel {
  height: 100%;
  padding: 5vh 1vw 2vw 1vw !important;
}

.pcfGraph {
  height: 26.8vh;
  margin-bottom: 0.5vh;
}
