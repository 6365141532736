.topContent {
  height: 100%;
}

.topContentLeft {
  height: 100%;
  float: left;
  padding-left: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topContentRight {
  height: 100%;
  float: right;
  padding-left: 1vw;
  align-items: center;
  justify-content: center;
  display: flex;
}

.selectItem {
  padding-bottom: 1.5vh;
}

.selectBox {
  :global(.MuiSelect-select) {
    padding: 1vh 1.5vw !important;
  }
}
