.rightContent {
  height: 85vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}
.lingGraph {
  height: 48vh;
  margin-bottom: 0.25vh;
}
.barGraph {
  height: 13vh;
  margin-bottom: 0.25vh;
}
.harfBarGraph {
  height: 100%;
  width: 50%;
  padding-right: 2px;
  float: left;
  padding-bottom: 2px;
}
