.csvButton {
  svg {
    height: 5vh !important;
    width: 5vh !important;
  }
}

.addUserButton {
  width: 15vw;
  height: 5vh;
  font-size: 2.5vh !important;
}

.iconTitle {
  padding-left: 0.5vw;
  font-size: 2.5vh !important;
}
