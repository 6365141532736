.reset_button {
  margin-top: 2vh !important;
  width: 20vw;
  font-size: 2.5vh !important;
  padding: 1vh !important;
}

.newPassword {
  padding-left: 2vw;
  float: left;
  width: 45vw;
}

.enterCharacters {
  padding-top: 1vh;
  padding-left: 2vw;
  float: left;
  width: 40vw;
}

.explain {
  font-size: 3vh !important;
}

.title {
  font-size: 4vh !important;
}
