$side-menu-open-width: 12vw; //サイドメニューOPEN時の幅
$side-menu-not-open-width: 5vw; //サイドメニューNotOPEN時の幅
$header-height: 7vh; //ヘッダーの高さ
$base-color: #319c77; //ヘッダーの高さ
$base-sub-color: #2baa8a; //ヘッダーの高さ
$base-font-color: white; //ヘッダーの高さ
$header-menu-fontsize: 1vw; //ヘッダーメニューフォントサイズ
$side-menu-fontsize: 1vw; //サイドメニューフォントサイズ
$side-menu-select-color: #5cddbc; //サイドメニュー選択カラー
$setting-page-color: #222222; //サイドメニューベースカラー（各種設定画面）
$setting-page-select-color: #c0c0c0; //サイドメニュー選択カラー（各種設定画面）
