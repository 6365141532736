.userContract {
  height: 100%;
}
.userInfo {
  width: 100%;
  height: 100%;
}
.title {
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: 1.5vw !important;
  height: 7vh;
}

.userInfoTable {
  height: 89%;
  thead,
  tr,
  th,
  td {
    height: 5vh;
    font-size: 0.8vw;
    line-height: 1.5vh;
    padding-top: 0vw;
    padding-bottom: 0vw;
    padding-right: 1vw;
    padding-left: 1vw;
  }
}

.tableCell {
  word-wrap: break-word;
  white-space: normal;
}
