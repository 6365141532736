.trendChartForm {
  height: 100%;
}

.trendChartItem {
  height: 100%;
  padding-top: 1vh !important;
  padding-bottom: 1vh !important;
}

.trendGraph {
  height: 60%;
  padding-bottom: 1vh !important;
}

.spreadSheet {
  height: 40%;
}
