@import "../../../common/styles/common_styles.scss";
.icons {
  text-align: left;
  align-items: center;
  height: $header-height;
  display: flex;
  svg {
    width: 3.8vw;
    height: 2vw;
  }
}
.iconsTitle {
  text-align: left;
  align-items: center;
  height: $header-height;
  display: flex;
  font-size: 1.1vw !important;
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  padding-left: 0.25vw;
}
.econipass {
  width: 50px;
  margin: 10px;
}

.openDiv {
  position: fixed;
  bottom: 0.1vh;
  left: 0;
  min-width: $side-menu-open-width !important;
}

.openSettingDiv {
  position: fixed;
  bottom: 0.5vh;
  left: 0.5vw;
}

.closeDiv {
  position: fixed;
  bottom: 0.1vh;
  left: 0;
  min-width: $side-menu-not-open-width !important;
}

.closeSettingDiv {
  position: fixed;
  bottom: 0.5vh;
  left: 0.5vw;
}
.openButton {
  display: grid;
  justify-items: end;
  padding-right: 0.5vw !important;
  svg {
    height: 2vw;
    width: 2vw;
  }
}
.closeButton {
  display: grid;
  justify-items: end;
  padding-right: 0.5vw !important;
  svg {
    height: 2vw;
    width: 2vw;
  }
}
.backToHomeButton {
  width: calc($side-menu-open-width - 1vw);
  border-radius: 4px !important;
  padding: 0.3vw !important;
  background-color: $base-color !important;
  div {
    background-color: $base-color !important;
  }
}
.backToHomeCloseButton {
  width: 4vw;
  border-radius: 4px !important;
  padding: 0.3vw !important;
  background-color: $base-color !important;
  div {
    background-color: $base-color !important;
  }
}

.backToHomeButtonImage {
  height: 1.8vw !important;
  width: 1.8vw !important;
}

.backToHomeButtonTitle {
  padding-left: 0.25vw;
  font-size: 1vw !important;
}
