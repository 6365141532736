.errorAlert {
  position: fixed;
  width: 65vw;
  text-align: left;
  z-index: 10000;
  padding: 2vh 1vw !important;
  :global(.MuiAlert-icon) {
    font-size: 4.5vh;
    padding: 1vh 0;
    margin-right: 1vw;
  }
  :global(.MuiSvgIcon-root) {
    font-size: 4.5vh;
  }
  :global(.MuiAlertTitle-root) {
    font-size: 3vh;
  }
  :global(.MuiAlert-message) {
    font-size: 2.5vh;
    padding: 2vh 0;
  }
}
