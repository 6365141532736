.selectItemBox {
  justify-items: center;
}
.selectItem {
  width: 30vw;
}
.selectItemTitle {
  font-size: 2.5vh !important;
}
.leftGrid {
  display: flex;
  align-items: end;
}
.rightGrid {
  text-align: end;
  display: flex;
  align-items: end;
  justify-content: end;
}
