.pieGraph {
  height: 100%;
}

.title {
  margin-bottom: 0;
  font-size: 1.5vh !important;
  padding-top: 1vh !important;
  padding-left: 1vw !important;
}
