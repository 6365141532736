.searchItem {
  height: 50%;
  padding-bottom: 5px;
}

.searchButtonItem {
  display: grid;
  align-items: flex-end;
  padding-left: 0.5vw !important;
}
