@import "../../../common/styles/common_styles.scss";
.switchLabel {
  :global(.MuiTypography-root) {
    font-size: 2vh;
  }
  :global(.MuiSwitch-root) {
    height: 5vh;
    width: 5vw;
    padding: 1vh;
    :global(.MuiTouchRipple-root) {
      padding: 1vh;
    }
    :global(.MuiSwitch-thumb) {
      height: 4vh;
      width: 4vh;
    }
  }
  :global(.MuiSwitch-switchBase.Mui-checked):not(.Mui-disabled) {
    transform: translateX(3vw);
    color: $base-color !important;
  }
  :global(.MuiSwitch-switchBase.Mui-checked.Mui-disabled) {
    transform: translateX(3vw);
    color: #bdbdbd !important;
  }
  :global(.MuiSwitch-track) {
    background-color: $base-sub-color !important;
  }
}

.switch {
  :global(.MuiSwitch-switchBase) {
    padding: 0.6vh !important;
  }
}
