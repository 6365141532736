.title {
  border-bottom-style: outset;
  margin-left: 1vw;
  margin-right: 1vw;
  font-size: 3vh !important;
  min-width: 60vh !important;
}

.icon {
  padding: 1vh !important;
}

.iconButton {
  font-size: 6vh !important;
}

.format {
  height: 77%;
}

.example {
  height: 22%;
  overflow: auto;
  margin-top: 1vh;
}

.exampleBox {
  text-align: left;
  padding-top: 0.2vh;
  padding-left: 1vw;
  font-size: 2.8vh !important;
  height: 100%;
  width: 100%;
  min-width: 60vh !important;
}

.exampleIconButton {
  font-size: 4vh !important;
}
