@import "/src/econipass/common/styles/common_styles.scss";

.spaceItem {
  padding-left: 1vw;
}

.iconImage {
  vertical-align: middle;
  color: white;
  height: 1.6vw !important;
  width: 1.6vw !important;
}

.iconTitle {
  color: white;
  padding-left: 0.1vw !important;
  font-size: $header-menu-fontsize !important;
  font-weight: bold !important;
}
