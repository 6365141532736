.input_new_password {
  width: 40vw;
  height: 9vh;
  font-size: 3vh !important;
}
.input_new_password_form {
  label {
    font-size: 3vh !important;
  }
}
