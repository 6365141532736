@import "/src/econipass/common/styles/common_styles.scss";
.iconImage {
  vertical-align: middle;
  border-radius: 10%;
  height: 3vh;
  width: 3vh;
}

.iconTitle {
  color: white;
  padding-left: 1vw;
  font-size: $header-menu-fontsize !important;
  font-weight: bold !important;
  width: 6vw;
  text-align: start;
}
