.userFilterForm {
  height: 80%;
  background-color: #f5f5f5;
}

.title {
  font-size: 2vh !important;
  align-content: center;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 10%;
  text-align: right;
}

.inputSearchText {
  width: 85%;
  height: 5vh;
  align-self: center;
  input {
    font-size: 2vh !important;
  }
  svg {
    height: 4vh !important;
    width: 4vh !important;
  }
}
