.menuContent {
  display: grid;
  height: 40vh;
}

.cardContent {
  border: none;
}

.logoutButton {
  font-size: 2vh !important;
  align-items: flex-end !important;
  text-align: center !important;
}

.accountItem {
  font-size: 2.5vh !important;
}
