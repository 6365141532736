.title {
  font-size: 4vh !important;
}

.saveButton {
  min-width: none;
  width: 20vw !important;
  padding: 1vh 1vw !important;
  font-size: 2.5vh !important;
}
