.helpLinkBox {
  justify-items: center;
  display: inline-flex;
}
.iconImage {
  height: 4vh !important;
  width: 4vh !important;
  float: left;
  align-items: right;
}
.iconTitle {
  font-weight: bold !important;
  font-size: 1.2vw !important;
}
.manualButton {
  height: 8vh !important;
  width: 32vw !important;
  border: 0.1vw solid #000 !important;
  border-radius: 0% !important;
  padding: 0px !important;
  padding-left: 1vw !important;
}
.title {
  width: 80%;
}
.download {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 0.1vw solid #000 !important;
  height: 100%;
}
.inquiryButton {
  height: 8vh !important;
  width: 15vw !important;
  border: 0.1vw solid #000 !important;
  border-radius: 0% !important;
  padding: 0px !important;
  padding-left: 1vw !important;
}
