.changeListButton {
  width: 15vw;
  height: 5vh;
  font-size: 2.5vh !important;
}

.reloadButton {
  svg {
    height: 5vh;
    width: 5vh;
  }
}
