.lineGraph {
  height: 100%;
}

.legend {
  svg {
    height: 1.5vh !important;
    width: 1.5vh !important;
  }
}

.graphBox {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.chart {
  position: absolute;
  height: 100% !important;
  top: 0%;
  left: 0%;
  :global(.recharts-legend-wrapper) {
    height: 4.5vh !important;
    overflow-y: auto;
  }
}

.chartFull {
  position: absolute;
  height: 90% !important;
  width: 95% !important;
  top: 7%;
  left: 2%;
  :global(.recharts-legend-wrapper) {
    height: 4.5vh !important;
    overflow-y: auto;
  }
}

.yAxisLabelFull {
  padding-top: 1.5vh;
  font-size: 2vh;
  :global .recharts-label {
    text-anchor: middle;
  }
}

.yAxisLabel {
  font-size: 1.5vh;
  :global .recharts-label {
    text-anchor: middle;
  }
}

.buttonDiv {
  position: absolute;
  display: flex;
  bottom: 0%;
  right: 0%;
}

.fullscreen {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.fullscreenExit {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.screenButton {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.title {
  font-size: 2vw !important;
  padding-top: 1vh !important;
  padding-left: 1vw !important;
}
