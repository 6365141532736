.graphBox {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.chart {
  position: absolute;
  top: 0%;
  left: 0%;
}

.iconBoxFull {
  position: absolute;
  top: 49%;
  left: 50%;
  display: flex;
}

.iconBox {
  position: absolute;
  top: 49%;
  left: 50%;
  display: flex;
}

.buttonDiv {
  position: absolute;
  display: flex;
  right: 0%;
  bottom: 0%;
}

.fullscreen {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.fullscreenExit {
  float: left;
}

.screenButton {
  float: left;
  svg {
    font-size: 4vh !important;
  }
}

.title {
  font-size: 2vw !important;
  padding-top: 1vh !important;
  padding-left: 1vw !important;
}
