@import "../../../common/styles/common_styles.scss";

.openMainContent {
  margin-top: $header-height; /* ヘッダーの高さと一致 */
  margin-left: $side-menu-open-width; /* サイドバーの幅と一致 */
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto; /* 必要に応じてスクロール */
  position: fixed;
  width: -webkit-fill-available;
  height: 100vh;
}
.notOpenMainContent {
  margin-top: $header-height; /* ヘッダーの高さと一致 */
  margin-left: $side-menu-not-open-width; /* サイドバーの幅と一致 */
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto; /* 必要に応じてスクロール */
  position: fixed;
  width: -webkit-fill-available;
  height: 100vh;
}

.mainContent {
  height: 100%;
  width: 100%;
}
