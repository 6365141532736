.spreadSheet {
  height: 100%;
}

.title {
  float: left;
  font-size: 1.5vh !important;
  padding-top: 1vh !important;
  padding-left: 1vw !important;
}

.inputText {
  float: right;
  padding-top: 1vh;
}

.multipleText {
  label {
    font-size: 2vh;
  }
  div {
    height: 3vh;
  }
  input {
    font-size: 2vh;
    width: 20vw;
    padding: 0.7vh 1vw;
  }
}

.spreadSheetItem {
  height: 77%;
}
