.dialog {
  overflow-x: hidden;
  :global(.MuiDialog-paper) {
    max-width: 60vw;
  }
}

.title {
  height: 20vh;
  align-content: center;
  justify-content: center;
  display: grid;
}

.titleName {
  font-size: 4vh !important;
  align-content: center;
  justify-content: center;
  display: grid;
}

.dialogContent {
  width: 50vw;
  height: 20vh;
  align-content: center;
  justify-content: center;
  display: grid;
}

.contentMessage {
  font-size: 3vh !important;
  align-content: center;
  justify-content: center;
  display: grid;
}

.button {
  font-size: 3vh !important;
}
