.singleDisplay {
  height: 100%;
  width: 100%;
}
.label {
  height: 20%;
  font-size: 1.5vh !important;
  line-height: 1.5vh !important;
  font-weight: bold !important;
  text-align: left;
  padding-top: 1.1vh !important;
  margin-bottom: 0vh !important;
  margin-left: 1vw !important;
}
.data {
  height: 58%;
  text-align: right;
  font-size: 4vh !important;
  line-height: 4vh !important;
  padding-top: 1.1vh !important;
  padding-bottom: 1.1vh !important;
  margin-right: 1.5vw !important;
}
.unit {
  height: 15%;
  font-size: 1.5vh !important;
  line-height: 1.5vh !important;
  text-align: right;
  margin-right: 1vw !important;
  margin-top: 0vh !important;
  padding-bottom: 1.1vh !important;
}
