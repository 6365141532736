.selectedItem {
  padding: 5px 0px 5px 0px !important;
  background-color: #5cddbc;
  div {
    background-color: #5cddbc;
  }
  &.childItem {
    padding-left: 10px !important;
  }
}
.listItem {
  padding: 5px 0px 5px 0px !important;
  &.childItem {
    padding-left: 10px !important;
  }
}

.sideButton {
  padding: 0px 0px 0px 10px !important;
}

.title {
  span {
    font-size: 10px;
  }
}

.itemIcon {
  svg {
    height: 20px;
    width: 20px;
  }
}
